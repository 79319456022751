import './AuthPage.css';
import React, { useEffect } from 'react';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import googleIcon from '../../assets/img/google-icon.svg';
import { useAuth } from 'src/provider/AuthProvider';
import { environment, getServerEnvironment } from 'src/env/env';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { parseUrl } from 'src/services/ParseUrl';
import { useNotification } from 'src/hooks/useNotification';
import { LoginForm } from './LoginForm';
import { AuthWrapper } from 'src/components/AuthWrapper/AuthWrapper';
import { persistor } from 'src/store';
import { selectManagedAccountId } from 'src/store/slices/companySlice';
import { useDispatch } from 'react-redux';
import { baseApi } from 'src/services';
import { setProjectId } from 'src/store/slices/projectsSlices';

const AuthPage = () => {
  const navigate = useNavigate();
  const { signInWithGoogle } = useAuth();
  const notification = useNotification();
  const dispatch = useDispatch();

  const loginByGoogle = async () => {
    const { REDIRECT_URI } = environment;
    const { GOOGLE_OAUTH_CLIENT_ID } = await getServerEnvironment();
    const scope = {
      profile: 'https://www.googleapis.com/auth/userinfo.profile',
      email: 'https://www.googleapis.com/auth/userinfo.email',
    };
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=${encodeURIComponent(`${scope.profile} ${scope.email}`)}&response_type=code&include_granted_scopes=true&&access_type=offline&state=state_parameter_passthrough_value&prompt=select_account+consent`;
  };

  useEffectOnce(() => {
    const auth = async () => {
      const queryParams = parseUrl(window.location.href);
      if (queryParams.code) {
        try {
          const response = await signInWithGoogle(queryParams.code);

          if (response instanceof Error) {
            return response.message && notification.error(response.message);
          }

          if (response.access) {
            return navigate('/dashboard');
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    auth();
    return () => {};
  }, []);

  useEffect(() => {
    dispatch(baseApi.util.resetApiState());
    dispatch(selectManagedAccountId(''));
    dispatch(setProjectId({ projectId: null, projectType: null }));
    persistor.flush().then(() => {
      return persistor.purge();
    });
  }, []);

  return (
    <AuthWrapper title="Welcome to CoSupport AI client cabinet!">
      <LoginForm />
      <p className="text-[#B8C1C6] text-center md:pt-2.5 pt-1">or</p>
      <Button className="google" onClick={loginByGoogle}>
        <img src={googleIcon} width="18px" alt="Google icon" />
        Sign in with Google Account
      </Button>
      <div
        className="pb-3 py-4 md:pt-7 text-[#B8C1C6] flex justify-center overflow-hidden items-center relative
                before:content-['']
                before:absolute
                before:h-px
                before:bg-[#B8C1C6]
                before:w-full"
      >
        <p
          className="
                inline-block
                text-[#B8C1C6]
                text-center
                relative
                bg-[#F1F6F6]
                px-6
               "
        >
          Don’t have account?
        </p>
      </div>
      <Button className="google" onClick={() => navigate('/register')}>
        Create your account
      </Button>
    </AuthWrapper>
  );
};

export default AuthPage;
